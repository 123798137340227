import { useState, useEffect } from "react";
import { Floating } from "./components/Floating";
import { Logo } from "./components/Logo";
import { Slider } from "./components/Slider";
import { SliderProducts } from "./components/SliderProducts";
import { SliderServices } from "./components/SliderServices";
import { Form } from "./components/Form";
import { Security } from "./components/Security";
import { Services } from "./components/Services";
import { PopUpForm } from "./components/PopUpForm";

import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [popUpForm, setPopUpForm] = useState(false);
  const [text, setText] = useState("alarma para casa");
  const brightness = popUpForm ? "fullPage brightness" : "fullPage";

  const url = window.location.pathname;

  const [data, setData] = useState(null);

  const fetchData = async () =>{
    const data = await fetch('landing-config.json')
    return data.json()
  }
  
  useEffect(() => {
    fetchData()
      .then((response) => {
        setData(response); // Store the resolved value in state
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handlerPopUpForm = (text) => {
    setText(text);
    openPopUpFormState();
  };

  const openPopUpFormState = () => {
    setPopUpForm(!popUpForm);
  };
  const closePopUpForm = () => {
    if (popUpForm) {
      setPopUpForm(!popUpForm);
    }
  };
  return (
    <div>
      {popUpForm ? (
        <div className="background-brightness">
          <PopUpForm text={text} handlerPopUpFormState={openPopUpFormState} />
        </div>
      ) : null}
      <>
      {data !== null ? (
        <div className={brightness} onClick={closePopUpForm}>
          <div className="top-bar">
            <Logo />
            <Floating data={data}/>
          </div>
          <div className="slider-position">
            {url === "/servicios" ? (
              <SliderServices data={data} handlerPopUpForm={handlerPopUpForm} />
            ) : url === "/productos" ? (
              <SliderProducts data={data} handlerPopUpForm={handlerPopUpForm} />
            ) : (
              <Slider data={data} handlerPopUpForm={handlerPopUpForm} />
            )}
          </div>
          <div className="form-position">
            <Form url={url} />
          </div>
          <div className="security-position">
            <Security data={data} />
          </div>
          <div className="services-position">
            <Services data={data} />
          </div>
          <div className="logo-position"></div>
        </div>
      ):(<></>)}
      </>
    </div>
  );
}

export default App;
