import React from "react";
import "../assets/css/floating.css";
import phoneIcon from "../assets/images/phoneIcon.svg";

export const Floating = (data) => {
  const phone = data.data.phoneNumber
  const numberIcon = data.data.phoneNumberIcon
  const phoneClaro = data.data.phoneNumberClaro
  const numberIconClaro = data.data.phoneNumberClaroIcon
  const url = window.location.pathname;
  return (
    <div className="floating-button" type="button">
      <img src={phoneIcon} alt="Phone" className="phoneIcon" />
      {url === "/mendoza" ? (
        <a href="tel:2614760867">
          {window.innerWidth < 1001 ? (
            <div>
              <b>261 476 0867</b>
            </div>
          ):(
            <div>
              <span>Obtener promo </span> <b>261 476 0867</b>
            </div>
          )}
        </a>
        ) : url === "/buenosaires" ? (
          <a href="tel:1152742480">
            {window.innerWidth < 1001 ? (
              <div>
                <b>11 5274 2480</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>11 5274 2480</b>
              </div>
            )}
          </a>
        ) : url === "/cordoba" ? (
          <a href="tel:3515717502">
            {window.innerWidth < 1001 ? (
              <div>
                <b>351 571 7502</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>351 571 7502</b>
              </div>
            )}
          </a>
        ) : url === "/rosario" ? (
          <a href="tel:3415295310">
            {window.innerWidth < 1001 ? (
              <div>
                <b>341 529 5310</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>341 529 5310</b>
              </div>
            )}
          </a>
        ) : url === "/santafe" ? (
          <a href="tel:3424134423">
            {window.innerWidth < 1001 ? (
              <div>
                <b>342 413 4423</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>342 413 4423</b>
              </div>
            )}
          </a>
        ) : url === "/bahiablanca" ? (
          <a href="tel:2914851105">
            {window.innerWidth < 1001 ? (
              <div>
                <b>291 485 1105</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>291 485 1105</b>
              </div>
            )}
          </a>
        ) : url === "/laplata" ? (
          <a href="tel:2214477890">
            {window.innerWidth < 1001 ? (
              <div>
                <b>221 447 7890</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>221 447 7890</b>
              </div>
            )}
          </a>
        ) : url === "/mardelplata" ? (
          <a href="tel:2234321275">
            {window.innerWidth < 1001 ? (
              <div>
                <b>223 432 1275</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>223 432 1275</b>
              </div>
            )}
          </a>
        ) : url === "/neuquen" ? (
          <a href="tel:2994360964">
            {window.innerWidth < 1001 ? (
              <div>
                <b>299 436 0964</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>299 436 0964</b>
              </div>
            )}
          </a>
        ) : url === "/sanluis" ? (
          <a href="tel:2664245571">
            {window.innerWidth < 1001 ? (
              <div>
                <b>266 424 5571</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>266 424 5571</b>
              </div>
            )}
          </a>
        ) : url === "/sanjuan" ? (
          <a href="tel:2644660711">
            {window.innerWidth < 1001 ? (
              <div>
                <b>264 466 0711</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>264 466 0711</b>
              </div>
            )}
          </a>
        ) : url === "/tucuman" ? (
          <a href="tel:3814081912">
            {window.innerWidth < 1001 ? (
              <div>
                <b>381 408 1912</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>381 408 1912</b>
              </div>
            )}
          </a>
        ) : url === "/seguridad" ? (
          <a href="tel:08003451884">
            {window.innerWidth < 1001 ? (
              <div>
                <b>0800 345 1884</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>0800 345 1884</b>
              </div>
            )}
          </a>
        ) : url === "/promo" ? (
          <a href={`tel:${phoneClaro}`}>
            {window.innerWidth < 1001 ? (
              <div>
                <b>{numberIconClaro}</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>{numberIconClaro}</b>
              </div>
            )}
          </a>
        ) : (
          <a href={`tel:${phone}`}>
            {window.innerWidth < 1001 ? (
              <div>
                <b>{numberIcon}</b>
              </div>
            ):(
              <div>
                <span>Obtener promo </span> <b>{numberIcon}</b>
              </div>
            )}
          </a>
        )}
    </div>
  );
};
