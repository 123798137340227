import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

import kitAlarma1 from "../assets/images/kitAlarma1.png";
import kitAlarma2 from "../assets/images/kitAlarma2.png";
import kitAlarma1Mobile from "../assets/images/kitAlarma1Mobile.png";
import kitAlarma2Mobile from "../assets/images/kitAlarma2Mobile.png";

import "../assets/css/slider.css";

export const SliderProducts = (data, { handlerPopUpForm }) => {
  const [index, setIndex] = useState(0);
  const [text, setText] = useState("Kit de alarma Inalámbrico 2.2");
  const phone = data.data.phoneNumber

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    selectedIndex === 0
      ? setText("Kit de alarma Inalámbrico 2.2")
      : setText("Panel inteligente");
  };
  const url = window.location.pathname;

  return (
    <div className="slider-title">
      <h2 className="slider-products-title">
        NUESTROS KITS SE ADAPTAN A TU HOGAR
      </h2>

      {window.screen.width < 1001 ? (
        <h3>{text}</h3>
      ) : (
        <h3>
          Nuestros servicios de monitoreo se adaptan a las necesidades de tu
          hogar y familia
        </h3>
      )}

      <div className="carousel">
        {url === "/seguridad" ? (
          <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href="tel:08003451884">
                <img
                  src={kitAlarma1Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href="tel:08003451884">
                <img src={kitAlarma1} alt={text} className="d-block w-100" />
              </a>
            )}
          </Carousel.Item>
          <Carousel.Item>
            {window.screen.width < 1001 ? (
              <a href="tel:08003451884">
                <img
                  src={kitAlarma2Mobile}
                  alt={text}
                  style={{ width: "100%", height: "130vw" }}
                />
              </a>
            ) : (
              <a href="tel:08003451884">
                <img src={kitAlarma2} alt={text} className="d-block w-100" />
              </a>
            )}
          </Carousel.Item>
        </Carousel>
        ):(
          <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href={`tel:${phone}`}>
                  <img
                    src={kitAlarma1Mobile}
                    alt={text}
                    style={{ width: "100%", height: "130vw" }}
                  />
                </a>
              ) : (
                <a href={`tel:${phone}`}>
                  <img src={kitAlarma1} alt={text} className="d-block w-100" />
                </a>
              )}
            </Carousel.Item>
            <Carousel.Item>
              {window.screen.width < 1001 ? (
                <a href={`tel:${phone}`}>
                  <img
                    src={kitAlarma2Mobile}
                    alt={text}
                    style={{ width: "100%", height: "130vw" }}
                  />
                </a>
              ) : (
                <a href={`tel:${phone}`}>
                  <img src={kitAlarma2} alt={text} className="d-block w-100" />
                </a>
              )}
            </Carousel.Item>
          </Carousel>
        )}

      </div>
    </div>
  );
};
